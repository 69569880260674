import http from "@/utils/request";

// post
export function login(data) {
    return http({
        method: "post",
        url: "/webLogin.nd",
        data
    });
}

// 获取验证码
export function imgCode() {
    return http({
        method: 'get',
        url: '/checkImg2.nd'
    })
}

// 验证验证码是否正确
export function testImgCode(data){
    return http({
        url:'/checkMsgCode.nd',
        method:'post',
        data
    })
}

// 原手机可用点击下一步匹配账号和手机号并发送短信验证码

export function getMessageCode(data) {
    return http({
        method: 'post',
        url: '/pwdGetMsmCode.nd',
        data
    })
}