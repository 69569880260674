import {
    getMessageCode,
    // 验证验证码是否正确
    testImgCode
} from './api'

export default {
    name: 'findPassword',
    data() {
        return {
            // 验证码倒计时
            codevalue: '获取验证码',
            clickCancle: false,
            // 图形验证码
            imgURL: '',
            loginerrorPH: false,
            parameter: {},
            code: ''
        }
    },
    mounted() {
        this.getCode()
        this.parameter = JSON.parse(Storage.getItem('phoneCanUse'))
        document.querySelector('body').setAttribute('style', 'background-color:#F0F3F7')
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style', 'background-color:#FFFFFF')
    },
    methods: {
        // 
        tologin() {
            this.$router.push('/login')
        },

        // 下一步按钮
        toset() {

            if (this.code == '') {
                this.$message.warning('请输入验证码');
                return;
            }
            var phoneObj = sessionStorage.getItem("phoneCanUse")
            var obj = JSON.parse(phoneObj);
            let data = {
                account: obj.account,
                msgCode: this.code
            }
            testImgCode(data).then(res => {
                let resdata = res.data;
                if (resdata.code == 1) {
                    this.$message.warning(resdata.msg);
                } else if (resdata.code == 0) {
                    this.$router.push('reset')
                }
            })


            sessionStorage.setItem('msmCode', this.code)

        },
        // 获取手机验证码
        getCode() {
            var totaltime = 60
            this.clickCancle = true
            var times = setInterval(() => {
                totaltime--;
                this.codevalue = '已发送' + totaltime + 's'
                if (totaltime == 0) {
                    clearInterval(times);
                    this.codevalue = '获取验证码';
                    this.clickCancle = false;
                };
            }, 1000)
        },
        getCodeN() {
            let data = this.parameter
            getMessageCode(data).then(res => {
                if (res.data.status == 'true') {
                    this.$message.success(res.data.msg)
                    this.getCode();
                } else {
                    this.$message.warning(res.data.msg)
                }

                // if (res.data.status) {
                //     sessionStorage.setItem('phoneCanUse', JSON.stringify(this.loginNum))
                //     this.$router.push('getCode')
                // }
            })
        },

    }
}